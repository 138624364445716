import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Partner",
  "path": "/partner",
  "date": "2020-03-12T00:00:00.000Z",
  "displaySidebar": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Partner with us`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      